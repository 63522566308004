import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { Select, TextField } from 'unform-material-ui';
import { useSelector } from 'react-redux';
import createFormdata from 'object-to-formdata';
import ReactPlayer from 'react-player';

import { ProductAutocomplete } from '~/components';
import { useBannersHome } from '../context';
import Dialog from './Dialog';
import FieldImage from './ImageField';
import { useBannerForm } from './context';
import { getImagePreview } from './helpers';
import {
  Container,
  PreviewContainer,
  FormContainer,
  TwoColumnsField,
  DateTimeField,
} from './styles';

const BannerFormDialog = () => {
  const { data, closeForm } = useBannerForm();
  const { addNewBanner, updateBanner } = useBannersHome();
  const [bannerPreview, setBannerPreview] = useState(null);

  const [formState, setFormState] = useState({});
  const { product } = useSelector(state => state.user);

  const banner = useMemo(
    () => ({
      id: get(data, 'idBannerHome', null),
      image: get(data, 'bannerImage', null),
      url: get(data, 'url', null),
      urlVideo: get(data, 'urlVideo', null),
      product: get(data, 'produto.id', null),
      status: get(data, 'status', 0),
      validity: {
        start: get(data, 'dataInicio', null),
        end: get(data, 'dataFim', null),
      },
    }),
    [data]
  );

  const dialogTitle = useMemo(() => {
    return banner.id ? `Editando banner #${banner.id}` : 'Novo banner';
  }, [banner.id]);

  const initialData = useMemo(() => {
    if (banner.id)
      return {
        dataInicio: get(data, 'dataInicio', null),
        dataFim: get(data, 'dataFim', null),
        status: get(data, 'status', 1),
        ordem: get(data, 'ordem', ''),
      };

    return { status: 1 };
  }, [data, banner.id]);

  const hasSelectedProduct = useMemo(() => {
    return get(product, 'id', null) !== null;
  }, [product]);

  const updateField = useCallback(
    (fieldName, fieldValue) => {
      setFormState(oldState => ({ ...oldState, [fieldName]: fieldValue }));
    },
    [setFormState]
  );

  const handleSubmit = useCallback(
    async payload => {
      const isNew = banner.id === null;
      const idProduto = get(product, 'id', undefined);
      const formdata = createFormdata({ ...payload, idProduto, ...formState });

      if (isNew) {
        await addNewBanner(formdata).then(() => {
          closeForm();
          setTimeout(() => {
            setFormState({});
          }, 1);
        });
      } else {
        await updateBanner(banner.id, formdata).then(() => {
          closeForm();
          setTimeout(() => {
            setFormState({});
          }, 1);
        });
      }
    },
    [banner.id, formState, addNewBanner, updateBanner, closeForm, product]
  );

  const handleCancel = useCallback(() => {
    setFormState({});
  }, [setFormState]);

  useUpdateEffect(() => {
    if (banner.product) updateField('idProduto', String(banner.product));
  }, [banner, updateField]);

  useUpdateEffect(() => {
    (async function handlePreview() {
      const preview = (await getImagePreview(banner.image)) || banner.url;
      if (banner.image) updateField('image', banner.image);
      setBannerPreview(preview);
    })();
  }, [banner.image, banner.url, updateField]);

  useEffect(() => {
    if (product.id) updateField('idProduto', product.id);
  }, [product, updateField]);

  return (
    <Dialog title={dialogTitle} onCancel={handleCancel} formId="banner-home">
      <Container>
        <PreviewContainer>
          <FieldImage
            src={bannerPreview}
            onChange={bannerImage => {
              updateField('image', bannerImage);
            }}
          />

          <ReactPlayer
            width={250}
            height={150}
            style={{ marginTop: 40 }}
            url={get(formState, 'urlVideo', banner.urlVideo)}
          />
        </PreviewContainer>

        <FormContainer
          id="banner-home"
          onSubmit={handleSubmit}
          initialData={initialData}
        >
          <TwoColumnsField>
            <TextField name="ordem" label="Ordem" />
            <Select
              name="status"
              label="Status"
              options={[
                { value: 0, label: 'Inativo' },
                { value: 1, label: 'Ativo' },
              ]}
            />
          </TwoColumnsField>
          <DateTimeField
            name="dataInicio"
            format="dd/MM/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            label="Data Início"
            value={get(formState, 'dataInicio', banner.validity.start)}
            onChange={date => {
              updateField('dataInicio', date);
            }}
          />
          <DateTimeField
            name="dataFim"
            format="dd/MM/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            label="Data Fim"
            value={get(formState, 'dataFim', banner.validity.end)}
            onChange={date => {
              updateField('dataFim', date);
            }}
          />
          <TextField
            name="urlVideo"
            label="URL Vídeo"
            margin="normal"
            autoComplete="off"
            value={get(formState, 'urlVideo', banner.urlVideo)}
            onChange={newUrlVideo => {
              updateField('urlVideo', newUrlVideo.currentTarget.value);
            }}
            style={{ background: '#ffffff', marginTop: 60 }}
          />
          {!hasSelectedProduct && (
            <div style={{ marginTop: 15 }}>
              <ProductAutocomplete
                label="Produto"
                valueKey="id"
                value={String(get(formState, 'idProduto', ''))}
                onChange={evt => {
                  updateField('idProduto', evt.target.value);
                }}
              />
            </div>
          )}
        </FormContainer>
      </Container>
    </Dialog>
  );
};

export default BannerFormDialog;
