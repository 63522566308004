import React from 'react';
import { ConfirmProvider } from 'material-ui-confirm';

import { BannersHomeProvider } from './context';
import BannersHomeList from './List';
import BannerForm, { BannerFormProvider } from './Form';
import { UnauthorizedGhost, AccessControl } from '~/components';
import { BannersHomePermissionProvider } from './context-permissions';

const BannersHomePage = () => (
  <ConfirmProvider>
    <BannersHomePermissionProvider>
      <BannersHomeProvider>
        <BannerFormProvider>
          <AccessControl
            can="read_banners_home"
            renderNoAccess={() => <UnauthorizedGhost />}
            displayError
          >
            <BannersHomeList />
          </AccessControl>
          <AccessControl can="create_banner_home">
            <BannerForm />
          </AccessControl>
        </BannerFormProvider>
      </BannersHomeProvider>
    </BannersHomePermissionProvider>
  </ConfirmProvider>
);

export default BannersHomePage;
