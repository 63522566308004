/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import {
  Grid,
  Button,
  CircularProgress,
  Icon,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { TextField, Select } from 'unform-material-ui';
import * as Yup from 'yup';
import { get, set, defaults } from 'lodash';
import { pickAll } from 'ramda';

import { format, parseISO } from 'date-fns';
import { AccessControl } from '~/components';
import { Content } from '~/styles/global';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import {
  TextMaskNumber,
  TextMaskTime,
  TextMaskTimeSecond,
} from '~/components/masks';
import InstitutionsAutocomplete from '~/components/InstitutionsAutocomplete';
import { ColorColor } from './styles';

// sign of the function compoment
export default function PromotionConfigForm({ product }) {
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState();
  const [typeSweepstake, setTypeSweepstake] = useState();
  const [typeMatrix, setTypeMatrix] = useState();
  const [matrizEmbaralhada, setMatrizEmbaralhada] = useState();
  const [meioEntregaCupom, setMeioEntregaCupom] = useState('plataforma');
  const [config, setConfig] = useState();
  const [checkboxes, setCheckboxes] = useState({});
  const cores = [
    { value: 0, label: <ColorColor color="#773386">&nbsp;#773386</ColorColor> },
    { value: 1, label: <ColorColor color="#ac0808">&nbsp;#ac0808</ColorColor> },
    { value: 2, label: <ColorColor color="#faa21d">&nbsp;#faa21d</ColorColor> },
    { value: 3, label: <ColorColor color="#8b5528">&nbsp;#8b5528</ColorColor> },
    { value: 4, label: <ColorColor color="#00a7b9">&nbsp;#00a7b9</ColorColor> },
  ];

  const toNumber = str => {
    return parseInt(String(str).replace(/\D+/g, ''), 10) || 0;
  };

  // Yup form schema validation
  const schema = Yup.object().shape({
    id: Yup.number(),
    config: Yup.object().shape({
      corPromocao: Yup.string().required('Campo obrigatório'),
      tipoSorteio: Yup.string().required('Campo obrigatório'),
      numeroPlano: Yup.string()
        .nullable()
        .optional(),
      numTituloInicio: Yup.string().required('Campo obrigatório'),
      numTituloFim: Yup.string()
        .required('Campo obrigatório')
        .test(
          'ni-nf-range-valid',
          'Número Final deve ser maior ou igual ao número Inicial',
          ntf => {
            const nti =
              document.getElementById('config.numTituloInicio').value || 0;
            return toNumber(ntf) >= toNumber(nti);
          }
        ),
      pulo: Yup.string().required('Campo obrigatório'),
      horarioSorteio: Yup.string()
        .required('Campo obrigatório')
        .matches(
          /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
          'Horário inválido'
        ),
      categoria: Yup.string().required('Campo obrigatório'),
      matriz: Yup.string().required('Campo obrigatório'),
      idInstituicao: Yup.string().required('Campo obrigatório'),
      horaVigenciaInicial: Yup.string()
        .required('Campo obrigatório')
        .matches(
          /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
          'Horário inválido'
        ),
      horaVigenciaFinal: Yup.string()
        .required('Campo obrigatório')
        .matches(
          /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
          'Horário inválido'
        ),
      qtdMinimaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdMaximaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdSugerida1: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdSugerida2: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      meioEntregaCupom: Yup.string(),
      tipoMatriz: Yup.string().when(
        'meioEntregaCupom',
        // eslint-disable-next-line no-unused-vars
        ([value], schemaReplace) => {
          return meioEntregaCupom === 'couponService'
            ? schemaReplace.required('Campo obrigatório')
            : schemaReplace.notRequired();
        }
      ),
      matrizEmbaralhada: Yup.string(),
    }),
  });

  // Send form to api
  const handleSubmit = async dataUpdate => {
    dataUpdate.produto = product.id;
    if (dataUpdate.config.pulo)
      dataUpdate.config.pulo = toNumber(dataUpdate.config.pulo);
    if (dataUpdate.config.matriz)
      dataUpdate.config.matriz = parseInt(dataUpdate.config.matriz, 10);
    if (dataUpdate.config.categoria)
      dataUpdate.config.categoria = parseInt(dataUpdate.config.categoria, 10);
    if (dataUpdate.config.numTituloInicio)
      dataUpdate.config.numTituloInicio = toNumber(
        dataUpdate.config.numTituloInicio
      );
    if (dataUpdate.config.numTituloFim)
      dataUpdate.config.numTituloFim = toNumber(dataUpdate.config.numTituloFim);
    if (dataUpdate.config.idInstituicao)
      dataUpdate.config.idInstituicao = parseInt(
        dataUpdate.config.idInstituicao,
        10
      );
    if (
      dataUpdate.config.meioEntregaCupom === 'couponService' &&
      typeMatrix &&
      dataUpdate.config.tipoMatriz
    ) {
      dataUpdate.config.couponServiceMatrixSize = typeMatrix.find(
        item => item.value === dataUpdate.config.tipoMatriz
      ).size;
    }

    if (dataUpdate.config.matrizEmbaralhada === 'desligado') {
      dataUpdate.config.matrizEmbaralhada = undefined;
    }
    // checkboxes
    set(
      dataUpdate,
      'config',
      defaults(
        { ...dataUpdate.config, ...checkboxes },
        {
          vendaOnline: false,
          triagemHabilitada: false,
          imprimirCartao: false,
          mostraGame: false,
          numeroExtra: false,
        }
      )
    );

    try {
      setLoading(true);
      const verb = config && config.id ? 'put' : 'post';
      if (
        (hasPermission('create_promotion_config') && verb === 'post') ||
        (hasPermission('update_promotion_config') && verb === 'put')
      ) {
        await api[verb](
          `/promotions/config/${(config && config.id) || ''}`,
          dataUpdate
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePlataformaEnvioChange = event => {
    if (event.target.dataset.value) {
      setMeioEntregaCupom(event.target.dataset.value);
    }
  };

  const getCheckboxValue = useCallback(
    pathName => {
      const value = get(
        checkboxes,
        pathName,
        get(initialData, `config.${pathName}`, false)
      );
      return value;
    },
    [checkboxes, initialData]
  );

  const updateCheckboxValue = useCallback(
    pathName => {
      return evt => {
        const newCheckboxes = { ...checkboxes };
        const checked = get(evt, 'target.checked', false);
        set(newCheckboxes, pathName, checked);
        setCheckboxes(newCheckboxes);
      };
    },
    [checkboxes, setCheckboxes]
  );

  function getDateFormat(data) {
    const newDate = parseISO(data);
    newDate.setUTCDate(newDate.getUTCDate() + 1);
    return format(newDate, 'yyyy-MM-dd');
  }

  const getConfig = useCallback(async () => {
    const DEFAULT_INITIAL_CONFIG = {
      config: {
        vendaOnline: false,
        triagemHabilitada: false,
        mostraGame: false,
        imprimirCartao: false,
      },
    };
    try {
      setLoading(true);
      setInitialData(null);
      if (hasPermission('read_promotion_config')) {
        const { data } =
          (await api.get(`/promotions/config/${product.id}?byProduct=true`)) ||
          {};
        if (data) {
          if (data && data.config && data.config.horaVigencia) {
            if (data.config.horaVigencia[0]) {
              data.config.horaInicioVigencia = new Date(
                `2000-01-01T${data.config.horaVigencia[0]}`
              );
            }
            if (data.config.horaVigencia[1]) {
              data.config.horaFinalVigencia = new Date(
                `2000-01-01T${data.config.horaVigencia[1]}`
              );
            }

            if (data.config.extracao) {
              data.config.extracao = getDateFormat(data.config.extracao);
            }
          }
          setConfig(data);
          setCheckboxes({
            ...pickAll(
              [
                'mostraGame',
                'numeroExtra',
                'vendaOnline',
                'triagemHabilitada',
                'imprimirCartao',
              ],
              data.config
            ),
          });
          if (data.config.tipoSorteio) {
            set(
              data,
              'config.tipoSorteio',
              // eslint-disable-next-line radix
              data.config.tipoSorteio.map(number => number.id)
            );
          }
          if (data.config.tipoMatrizList) {
            const typeMatrizList = data.config.tipoMatrizList.map(type => {
              return {
                label: type.description,
                value: type.code,
                size: type.size,
              };
            });

            setTypeMatrix(typeMatrizList);
          }
          if (data.config.meioEntregaCupom) {
            setMeioEntregaCupom(data.config.meioEntregaCupom);
          }

          if (data.config.matrizEmbaralhadaList) {
            const matrizEmbaralhadaList = data.config.matrizEmbaralhadaList.map(
              type => {
                return {
                  label: type.description,
                  value: type.code,
                };
              }
            );

            setMatrizEmbaralhada(matrizEmbaralhadaList);
          }

          if (!data.config.matrizEmbaralhada) {
            data.config.matrizEmbaralhada = 'desligado';
          }

          setInitialData(data);
        } else {
          setInitialData(DEFAULT_INITIAL_CONFIG);
          setConfig({});
        }
      }
    } catch (err) {
      const statusCode = get(err, 'response.statusCode', 500);
      if (statusCode >= 400) {
        setInitialData(DEFAULT_INITIAL_CONFIG);
        setConfig({});
      }
    } finally {
      setLoading(false);
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      getConfig();
    } else {
      setConfig({});
    }
  }, [product, getConfig]);

  useEffect(() => {
    (async () => {
      const { data } = await api(`/sweepstake/types`);
      const resultTypeSweepstake = data.docs.map(sweepstake => {
        return {
          value: sweepstake.id,
          label: sweepstake.descricao,
        };
      });

      setTypeSweepstake(resultTypeSweepstake);
    })();
  }, []);

  const handleInstitutionChange = value => {
    document.getElementById('config.idInstituicao').value = value.target.value;
  };

  const renderForm = () => {
    return (
      <Form
        schema={schema}
        context={{ produto: product.cod }}
        autoComplete="off"
        autoCapitalize="off"
        noValidate
        onSubmit={handleSubmit}
        initialData={initialData}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskTime,
                  }}
                  name="config.horarioSorteio"
                  label="Horário do Sorteio"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {product.cod !== 'instantaneo' &&
                product.cod !== 'diadasorte' &&
                product.cod !== 'sabadaodasorte' && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="config.numeroPlano"
                      label="Número plano"
                      margin="normal"
                      type="number"
                      onInput={e => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value, 10)
                        )
                          .toString()
                          .slice(0, 3);
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                )}
              <Grid item xs={12} md={12}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.qtdMinimaCupons"
                  label="Quantidade mínima"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="config.tipoSorteio">&nbsp;</InputLabel>
                <Select
                  name="config.tipoSorteio"
                  label="Tipo do sorteio"
                  variant="outlined"
                  multiple
                  style={{ width: '100%', align: 'left' }}
                  options={typeSweepstake || []}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskTimeSecond,
                  }}
                  name="config.horaVigenciaInicial"
                  label="Horário Inicio Vigência"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskTimeSecond,
                  }}
                  name="config.horaVigenciaFinal"
                  label="Horário Final Vigência"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputLabel htmlFor="config.corPromocao">&nbsp;</InputLabel>
                <Select
                  name="config.corPromocao"
                  label="Cor Promoção"
                  variant="outlined"
                  options={cores}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <InputLabel htmlFor="config.categoria">&nbsp;</InputLabel>
                <Select
                  name="config.categoria"
                  label="Categoria"
                  variant="outlined"
                  options={
                    product.cod === 'instantaneo'
                      ? [{ value: 1, label: 'Simples' }]
                      : [
                          { value: 1, label: 'Simples' },
                          { value: 2, label: 'Dupla' },
                          { value: 3, label: 'Tripla' },
                        ]
                  }
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.numTituloInicio"
                  label="Título Inicial"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.numTituloFim"
                  label="Título Final"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.pulo"
                  label="Pulo"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.matriz"
                  label="Matriz"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.qtdMaximaCupons"
                  label="Quantidade máxima"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.qtdSugerida1"
                  label="Quantidade sugerida 1"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  name="config.qtdSugerida2"
                  label="Quantidade sugerida 2"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <InputLabel htmlFor="config.meioEntregaCupom">
                  &nbsp;
                </InputLabel>
                <Select
                  name="config.meioEntregaCupom"
                  label="Plataforma de envio"
                  variant="outlined"
                  onClose={handlePlataformaEnvioChange}
                  options={[
                    { value: 'couponService', label: 'Coupon Service' },
                    { value: 'plataforma', label: 'Plataforma' },
                    { value: 'notApplicable', label: 'Não aplicável' },
                  ]}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <InputLabel htmlFor="config.tipoMatriz">&nbsp;</InputLabel>
                <Select
                  name="config.tipoMatriz"
                  label="Nome de tipo matriz"
                  variant="outlined"
                  disabled={meioEntregaCupom !== 'couponService'}
                  options={typeMatrix || []}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <InstitutionsAutocomplete
                  onChange={handleInstitutionChange}
                  value={initialData.config.idInstituicao || null}
                />
                <div style={{ display: 'none' }}>
                  <TextField type="text" name="config.idInstituicao" />
                </div>
              </Grid>

              <Grid item xs={6} md={3}>
                <InputLabel htmlFor="config.matrizEmbaralhada">
                  &nbsp;
                </InputLabel>
                <Select
                  name="config.matrizEmbaralhada"
                  label="Matriz embaralhada"
                  variant="outlined"
                  disabled={meioEntregaCupom !== 'couponService'}
                  value={config.matrizEmbaralhada || 'desligado'}
                  options={[
                    { label: 'Desligado', value: 'desligado' },
                    ...(matrizEmbaralhada || []),
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="config.triagemHabilitada"
                      checked={getCheckboxValue('triagemHabilitada')}
                      onChange={updateCheckboxValue('triagemHabilitada')}
                      color="primary"
                    />
                  }
                  label="Triagem habilitada"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="config.vendaOnline"
                      checked={getCheckboxValue('vendaOnline')}
                      onChange={updateCheckboxValue('vendaOnline')}
                      color="primary"
                    />
                  }
                  label="Venda Online"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="config.imprimirCartao"
                      checked={getCheckboxValue('imprimirCartao')}
                      onChange={updateCheckboxValue('imprimirCartao')}
                      color="primary"
                    />
                  }
                  label="Imprimir Cartão"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="config.mostraGame"
                      checked={getCheckboxValue('mostraGame')}
                      onChange={updateCheckboxValue('mostraGame')}
                      color="primary"
                    />
                  }
                  label="Mostra Game"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="config.numeroExtra"
                      checked={getCheckboxValue('numeroExtra')}
                      onChange={updateCheckboxValue('numeroExtra')}
                      color="primary"
                    />
                  }
                  label="Número Extra"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Botoes  */}
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={8} />
          <Grid item xs={12} md={4} align="right">
            <AccessControl
              can={['update_promotion_config', 'create_promotion_config']}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <Icon>save</Icon>&nbsp;Salvar
                  </>
                )}
              </Button>
            </AccessControl>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {product && initialData
            ? renderForm()
            : loading && <CircularProgress />}
        </Grid>
      </Grid>
    );
  };

  return <Content>{renderMain()}</Content>;
}

PromotionConfigForm.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
