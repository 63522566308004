import React, { useState, useRef } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import Card from './Card';
import ItemTypes from './ItemTypes';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';

const style = {
  width: '100%',
  textAling: 'center',
};

export default function Container({
  banners: cards,
  updateDraft,
  onMove,
  draft,
  onUpdate,
  onDelete,
}) {
  const [, drop] = useDrop({ accept: ItemTypes.CARD });
  const [loading, setLoading] = useState(false);
  const nullRef = useRef();

  const findCard = id => {
    const card = cards.filter(c => c.id === id)[0];
    return {
      card,
      index: cards.indexOf(card),
    };
  };

  const updateOrder = () => {
    if (hasPermission('sort_promotion_draft_banners')) {
      setLoading(true);
      const data = cards.map(c => c.id);
      api
        .put(`/promotions/drafts/${draft._id}/reorder-banners`, data)
        .then(() => {
          onUpdate(cards);
        })
        .finally(() => setLoading(false));
    }
  };

  const onDrop = () => {
    if (draft.status !== 'approved') updateOrder();
  };

  return (
    <>
      {loading && <CircularProgress />}
      <div ref={loading ? nullRef : drop} style={style}>
        {cards &&
          cards.map(card => (
            <Card
              key={card.id}
              id={card.id}
              url={card.url}
              urlVideoDraft={card.urlVideo}
              updateDraft={updateDraft}
              moveCard={onMove}
              findCard={findCard}
              loading={loading}
              onDrop={onDrop}
              onDelete={onDelete}
              draft={draft}
            />
          ))}
      </div>
    </>
  );
}

Container.propTypes = {
  banners: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onMove: PropTypes.func.isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateDraft: PropTypes.func.isRequired,
};
