/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Grid, Button, CircularProgress, Icon } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { MdDelete } from 'react-icons/md';
import ReactPlayer from 'react-player';
import Img from 'react-image';
import Skeleton from '@material-ui/lab/Skeleton';
import { cloneDeep, set } from 'lodash';
import { CardPlayerImageBanner } from './styles';
import ItemTypes from './ItemTypes';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';

const style = {
  border: '1px dashed gray',
  padding: '1rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};
const Card = ({
  id,
  url,
  urlVideoDraft,
  updateDraft,
  moveCard,
  findCard,
  onDrop,
  loading,
  draft,
  onDelete,
}) => {
  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag] = useDrag({
    canDrag: () => !loading,
    item: { type: ItemTypes.CARD, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => !loading,
    drop() {
      onDrop();
    },
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id);
        moveCard(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [clickDelete, setClickDelete] = useState(0);
  const [urlVideo, setUrlVideo] = useState();
  const [upVideo, setUpVideo] = useState(false);
  const [buttonSubmit, setButtonSubmit] = useState(true);
  const canDeleteBanner = useMemo(
    () => hasPermission('delete_promotion_draft_banner', { toast: false }),
    []
  );

  useEffect(() => {
    if (urlVideoDraft) {
      setUrlVideo(urlVideoDraft);
      setUpVideo(true);
      setButtonSubmit(false);
    }
  }, [urlVideoDraft]);
  const handleSubmitVideo = async () => {
    const result = await api.post(
      `/promotions/drafts/${draft._id}/video/${id}`,
      {
        urlVideo: urlVideo || null,
      }
    );

    const draftCopy = cloneDeep(draft);
    set(
      draftCopy,
      `data.banners.${originalIndex || 0}.urlVideo`,
      result.data.urlVideo
    );
    updateDraft(draftCopy);

    if (result.data && result.data.urlVideo) {
      setButtonSubmit(false);
      setUpVideo(true);
    }

    setUrlVideo(result.data.urlVideo);
  };

  const handleDeleteVideo = async () => {
    await api.delete(`/promotions/drafts/${draft._id}/video/${id}`);

    const draftCopy = cloneDeep(draft);

    set(draftCopy, `data.banners.${originalIndex || 0}.urlVideo`, '');
    updateDraft(draftCopy);

    setUpVideo(false);
    setButtonSubmit(true);
    setUrlVideo('');
  };

  const handleDeleteBanner = () => {
    if (clickDelete === 0) {
      setClickDelete(1);
      setTimeout(() => setClickDelete(0), 3000);
    } else if (hasPermission('delete_promotion_draft_banner')) {
      setLoadingDelete(true);

      api
        .delete(`/promotions/drafts/${draft._id}/banners/${id}`)
        .then(res => {
          if (res) onDelete(id);
        })
        .finally(() => setLoadingDelete(false));
    }
  };

  return (
    <div ref={node => drag(drop(node))} style={{ ...style, opacity }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={1}>
          <h2>{originalIndex + 1}</h2>
        </Grid>
        <Grid item xs={2}>
          <Img
            width={100}
            src={url}
            alt="Banner Home"
            loader={<Skeleton variant="rect" width={100} height={80} />}
          />
        </Grid>
        <Grid item xs={5} container justifyContent="flex-end">
          <TextField
            disabled={draft.status === 'approved'}
            id="outlined-required"
            label="URL de Vídeo"
            value={urlVideo}
            onChange={item => setUrlVideo(item.currentTarget.value)}
            variant="outlined"
            size="small"
          />
          {draft.status !== 'approved' && buttonSubmit && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ width: '27%', top: 10 }}
              onClick={() => handleSubmitVideo()}
            >
              <Icon>cloud_upload</Icon>&nbsp;Enviar
            </Button>
          )}
          {draft.status !== 'approved' && upVideo && (
            <Button
              style={{
                width: '27%',
                top: 10,
              }}
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => handleDeleteVideo()}
            >
              <MdDelete>&nbsp;</MdDelete>Excluir
            </Button>
          )}
        </Grid>
        <Grid item xs={2}>
          {urlVideo && (
            <CardPlayerImageBanner>
              <ReactPlayer url={urlVideo} light height={100} width={100} />
            </CardPlayerImageBanner>
          )}
        </Grid>

        <Grid item xs={2} align="right">
          {canDeleteBanner && draft.status !== 'approved' && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleDeleteBanner}
            >
              {loadingDelete ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : (
                <>
                  <MdDelete />
                  &nbsp;{clickDelete ? 'Confirma ?' : 'Excluir'}
                </>
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default Card;
